@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #808080;
  --white: #f8f8f8;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--white);
}

body {
  color: #333;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

.hero__primary-color {
  color: var(--primary);
}