.navbar__navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
    background-color: var(--darkgrey);
}

.navbar__navbar img {
    width: 80px;
}

.navbar__nav-menu {
    display: flex;
}

.navbar__nav-item {
    padding: 1rem;
}

.navbar__hamburger {
    display: none;
}

@media screen and (max-width: 940px) {
    .navbar__nav-menu {
        position: fixed;
        left: -100%;
        top: 100px;
        flex-direction: column;
        justify-content: center;
        background-color: var(--darkgrey);
        width:100%;
        height: 100%;
        text-align: center;
        transition: 0.4s;
    }

    .navbar__nav-menu.navbar__active {
        left: 0;
    }

    .navbar__nav-item {
        margin: 1.5rem;
        font-size: 1.8rem;
        justify-content: center;
    }

    .navbar__hamburger {
        display: flex;
    }
}